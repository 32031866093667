import { authAccessHeader } from "@/_helper/auth_header";
import HttpService from "./http.service";

class CandidateService {
  constructor() {
    this.httpService = new HttpService();
  }

  getCandidates(params) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .get(`/admin/candidate`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
    return result;
  }

  getStatus(payload) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .get(`/candidate/status`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  }

  getCandidate(uuid) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .get(`/admin/candidate/${uuid}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  }

  postCandidate(data) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .postApi("/candidate", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return result;
  }

  verifyCandidate(uuid, data) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .postApi(`/admin/candidate/${uuid}/verify`, data, authAccessHeader())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return result;
  }

  exportCandidate(params) {
    let that = this;
    let result = new Promise(function (resolve, reject) {
      that.httpService
        .download(`/admin/candidate/export`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
    return result;
  }
}

export default CandidateService;
