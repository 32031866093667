import { createStore } from "vuex";
import Auth from "./auth";

export default createStore({
  state: {
    isLoading: false
  },
  getters: {
    getLoadingStatus(state) {
      return state.isLoading;
    }
  },
  mutations: {
    setLoader(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {
    setLoader({ commit }, payload) {
      commit("setLoader", payload);
    }
  },
  modules: {
    auth: Auth
  }
});
