import VueCookies from "vue-cookies";
const cookies = VueCookies;
// export function authHeader() {
//   let user = JSON.parse(localStorage.getItem("auth"));

//   if (user && user.access_token) {
//     return {
//       Authrorization: "Bearer " + user.access_token
//     };
//   } else {
//     return {};
//   }
// }

export function authAccessHeader() {
  let access_token = cookies.get("access_token");

  if (access_token) {
    return {
      Authorization: "Bearer " + access_token
    };
  } else {
    return {};
  }
}
