<template>
  <div class="container-sm">
    <div class="row">
      <div class="offset-sm-3 col-md-6 border bg-white">
        <div class="d-flex justify-content-between align-items-baseline">
          <h4 class="mt-3">Status Pendaftaran</h4>
          <div>
            <div class="input-group">
              <input
                type="text"
                class="form-control-sm"
                v-model="payload.registration_number"
                placeholder="No. Pendaftaran"
                aria-label="No. Pendaftaran"
                aria-describedby="search"
              />
              <button
                class="btn btn-primary btn-sm"
                @click="getStatus()"
                type="button"
                id="search"
              >
                Lihat
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center mb-3" v-if="!result">
              <span class="text-muted fst-italic"> Tidak ada data </span>
            </div>
            <table class="table table-borderless" v-if="result">
              <thead>
                <tr>
                  <th class="text-end">Nama Peserta</th>
                  <td>{{ result.nama_lengkap }}</td>
                </tr>
                <tr>
                  <th class="text-end">Asal Sekolah</th>
                  <td>{{ result.sekolah }}</td>
                </tr>
                <tr>
                  <th class="text-end">Kategori</th>
                  <td>{{ result.kategori_lomba }}</td>
                </tr>
                <tr>
                  <th class="text-end">Kategori Juz</th>
                  <td>{{ result.kategori_juz }}</td>
                </tr>
                <tr>
                  <th class="text-end">Pilihan Juz</th>
                  <td>{{ result.pilihan_juz }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="text-center">
                    <a class="btn btn-lg btn-success" v-if="result.is_verified">
                      <i class="fa-regular fa-circle-check"></i>
                      Terverifikasi
                    </a>
                    <a class="btn btn-lg btn-danger" v-if="!result.is_verified">
                      <i class="fa-regular fa-circle-xmark"></i>
                      Belum Terverifikasi
                    </a>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CandidateService from "@/services/candidate.service";
const candidateService = new CandidateService();

export default {
  data: () => {
    return {
      result: null,
      payload: {
        registration_number: null
      }
    };
  },
  methods: {
    getStatus() {
      this.$store.dispatch("setLoader", true);
      candidateService
        .getStatus(this.payload)
        .then((response) => {
          setTimeout(() => {
            this.result = response.data.result;
            this.$store.dispatch("setLoader", false);
          }, 1000);
        })
        .catch((e) => {
          this.result = null;
          setTimeout(() => {
            this.$swal.fire({
              icon: "error",
              title: "Gagal",
              text: e.response.data.errors ?? e.message
            });
            this.$store.dispatch("setLoader", false);
          }, 1000);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  th {
    width: 50%;
  }
}
</style>
