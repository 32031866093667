import moment from "moment";

export function calcDate(date1, date2) {
  let d1 = moment(date1);
  let d2 = moment(date2);

  var years = d1.diff(d2, "year");
  d2.add(years, "years");

  var months = d1.diff(d2, "months");
  d2.add(months, "months");

  var days = d1.diff(d2, "days");

  return {
    year: years,
    month: months,
    day: days
  };
}
