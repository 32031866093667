import { createRouter, createWebHashHistory } from "vue-router";
import RegisterCandidate from "@/views/RegisterCandidate";
import StatusCandidate from "@/views/StatusCandidate";
import auth from "@/middlewares/auth";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../components/layout/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "register",
        component: RegisterCandidate
      },
      {
        path: "/status",
        name: "status",
        component: StatusCandidate
      }
    ]
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../components/layout/MainLayout.vue"),
    meta: {
      isAdmin: true,
      middleware: [auth]
    },
    redirect: () => {
      return "/admin/dashboard";
    },
    children: [
      {
        path: "auth",
        name: "admin.auth",
        children: [
          {
            path: "login",
            name: "admin.auth.login",
            component: () => import("../views/admin/AuthView.vue"),
            meta: {
              isAdmin: false,
              middleware: null
            }
          }
        ]
      },
      {
        path: "dashboard",
        name: "admin.dashboard",
        meta: {
          middleware: [auth]
        },
        children: [
          {
            path: "",
            name: "admin.dashboard.index",
            component: () => import("../views/admin/candidate/IndexView.vue")
          },
          {
            path: "page/:page",
            name: "admin.dashboard.page",
            component: () => import("../views/admin/candidate/IndexView.vue")
          }
        ]
      },
      {
        path: "candidate",
        name: "admin.candidate",
        middleware: [auth],
        children: [
          {
            path: ":id",
            name: "admin.candidate.show",
            component: () => import("../views/admin/candidate/ShowView.vue")
          }
        ]
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
