import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// BOOTSTRAPT
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// GLOBAL STYLES
import "./assets/scss/style.scss";

//Swal
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "../node_modules/@fortawesome/fontawesome-free/js/all.min.js";

//Vue Cookies
import VueCookies from "vue-cookies";

//Middleware
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  };
  return middleware[0]({
    ...context
  });
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueCookies, { expire: "7d" })
  .mount("#app");
