import { authAccessHeader } from "@/_helper/auth_header";
import { jsonToFormData } from "@/_helper/json_helper";
import axios from "axios";

const headers = {
  accept: "application/json"
};

class HttpService {
  constructor() {}

  async get(url, params = {}, header_add = {}) {
    header_add = {
      ...authAccessHeader()
    };
    let header = { ...headers, ...header_add };

    // console.log(header);
    return await axios.get(process.env.VUE_APP_API_URL + url, {
      headers: header,
      params: params
    });
  }

  async post(url, data) {
    return await axios.post(process.env.VUE_APP_URL + url, data, { headers });
  }

  async postApi(url, data, header_add = {}) {
    let header = { ...headers, ...header_add };

    let formData = jsonToFormData(data);
    return await axios.post(process.env.VUE_APP_API_URL + url, formData, {
      headers: header
    });
  }

  async deleteApi(url, data = {}, header_add = {}) {
    let header = { ...headers, ...header_add };
    let result = await axios.delete(process.env.VUE_APP_API_URL + url, {
      data: data,
      headers: header
    });
    return result;
  }

  async download(url, params = {}, header_add = {}) {
    header_add = {
      ...authAccessHeader()
    };
    let header = { ...headers, ...header_add };

    // console.log(header);
    return await axios.get(process.env.VUE_APP_API_URL + url, {
      headers: header,
      responseType: "blob",
      params: params
    });
  }
}

export default HttpService;
