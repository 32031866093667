<template>
  <router-view />
</template>
<script>
export default {
  beforeCreate() {
    // console.log(this.$store.getter(''));
  }
};
</script>
