import HttpService from "./http.service";
import { authAccessHeader } from "@/_helper/auth_header";

class AuthService {
  constructor() {
    this.httpService = new HttpService();
  }

  login(data) {
    let new_data = {
      ...data,
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: ""
    };
    return new Promise((resolve, reject) => {
      this.httpService
        .post("/oauth/token", new_data)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.httpService
        .deleteApi("/auth/logout", {}, authAccessHeader())
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

export default AuthService;
