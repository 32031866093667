import VueCookies from "vue-cookies";
import AuthService from "@/services/auth.service";

const authService = new AuthService();
const cookies = VueCookies;
const Auth = {
  namespaced: true,
  state: {
    access_token: cookies.get("access_token"),
    refresh_token: cookies.get("refresh_token")
  },
  getters: {
    loggedIn(state) {
      if (state.access_token) return true;
      return false;
    }
  },
  mutations: {
    loggedIn(state, payload) {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;

      cookies.set("access_token", state.access_token, payload.expires_in);
      cookies.set("refresh_token", state.refresh_token, payload.expires_in);
    },
    loggedOut() {
      // state.access_token = null;
      // state.refresh_token = null;

      cookies.remove("access_token");
      cookies.remove("refresh_token");
    }
  },
  actions: {
    login({ commit }, url, data) {
      // console.log(store.commit);
      return new Promise(function (resolve, reject) {
        authService
          .login(url, data)
          .then((response) => {
            commit("loggedIn", response.data);
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    logout({ commit }) {
      return new Promise(function (resolve, reject) {
        authService
          .logout()
          .then((response) => {
            commit("loggedOut");
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
      //   console.log(authService.login(url), url);
      //   //   console.log(this.$store, state, commit, abc);
    }
  }
};

export default Auth;
